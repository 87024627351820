import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StockPage = () => {
  const [stock, setStock] = useState([]);

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const response = await fetch('http://68.183.134.6:3001/api/stock', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setStock(data);
      } catch (err) {
        console.error('Error fetching stock:', err);
      }
    };
    fetchStock();
  }, []);

  // Format data for the chart
  const chartData = stock.map((stockItem) => ({
    name: stockItem.product,
    Quantité: stockItem.qte,
  }));

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-7xl mx-auto">

        {/* Chart for Quantité par produit */}
        <div className="bg-white rounded-lg shadow p-4 mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Quantité par produit</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Quantité" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Stock Table */}
        <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Liste des produits en stock</h2>
          <table className="w-full min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">N°</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Produit</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Entrepôt</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Quantité</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {stock.map((stockItem) => (
                <tr key={stockItem.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stockItem.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stockItem.product}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stockItem.warehouse}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stockItem.qte}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StockPage;