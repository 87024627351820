import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import img from '../images/logo.png';

const Navbar = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole');
  const [image, setImage] = useState(img);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    navigate('/login'); // Redirect to login page after logout
  };

  const getImages = async () => {
    try {
      const response = await fetch('http://68.183.134.6:3001/api/images', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        setImage(img);
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      data !== null ? setImage(`http://68.183.134.6:3001/uploads/${data}`) : setImage(img);
    }
    catch (err) {
      console.error('Error fetching data:', err);
    }
  }

  useEffect(() => {
    getImages();
  }, []);
  


  return (
    <nav className="bg-blue-500 p-4 text-white">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex space-x-4">
          <Link to="/dashboard" className="hover:text-gray-200">
            <img src={image} alt="Aronium" className="h-8" />
          </Link>
          <Link to="/dashboard" className="hover:text-gray-200">
            Dashboard
          </Link>
          {userRole === 'admin' && (
            <Link to="/subscription" className="hover:text-gray-200">
              Subscription Admin
            </Link>
          )}
          {(userRole === 'admin' || userRole === 'partner') && (
            <Link to="/partner" className="hover:text-gray-200">
              Partner
            </Link>
          )}
        </div>
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;