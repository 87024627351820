import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './nav-bar.jsx';

const Layout = () => {
  const location = useLocation(); // Now inside the Router context
  const isLoggedIn = localStorage.getItem('token');

  return (
    <>
      {/* Conditionally render Navbar if user is logged in and not on the login page */}
      {isLoggedIn && location.pathname !== '/login' && <Navbar />}

      {/* Render nested routes */}
      <Outlet />
    </>
  );
};

export default Layout;