import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const SalesPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [inputUserFilter, setInputUserFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch('http://68.183.134.6:3001/api/transactions', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setTransactions(data);
        setFilteredTransactions(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await fetch('http://68.183.134.6:3001/api/users', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setUsers(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    const getchart = async () => {
      try {
        // Fetch sales data from the backend
        const response = await fetch('http://68.183.134.6:3001/api/chart', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        const formattedSalesData = data.labels.map((label, index) => ({
          date: label,
          sales: data.values[index],
        }));

        setSalesData(formattedSalesData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    getchart();
    fetchTransactions();
    fetchUsers();
  }, []);

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'date_debut') {
      setDateDebut(value);
    } else if (name === 'date_fin') {
      setDateFin(value);
    }
  };

  useEffect(() => {
    const filterTransactions = () => {
      let filtered = transactions;

      if (dateDebut || dateFin) {
        filtered = filtered.filter((transaction) => {
          if (dateDebut && !dateFin) {
            return transaction.date >= dateDebut;
          }
          if (!dateDebut && dateFin) {
            return transaction.date <= dateFin;
          }
          return transaction.date >= dateDebut && transaction.date <= dateFin;
        });
      }

      if (inputUserFilter && inputUserFilter !== 'all') {
        filtered = filtered.filter((transaction) => transaction.user_name === inputUserFilter);
      }

      setFilteredTransactions(filtered);
    };

    filterTransactions();
  }, [dateDebut, dateFin, inputUserFilter, transactions]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <p className="text-gray-700">Chargement en cours...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <p className="text-red-500">Erreur: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      {/* Graphique des ventes par jour */}
      <div className="max-w-7xl mx-auto mt-6">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Évolution des ventes par jour</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={salesData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="sales" stroke="#8884d8" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <br></br>

      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Liste des ventes</h3>
          <div className="flex justify-start mb-4">
            <input
              type="date"
              className="border border-gray-300 rounded-lg px-3 py-2 mr-2"
              name="date_debut"
              id="date_debut"
              value={dateDebut}
              placeholder="Date de début"
              onChange={handleDateChange}
            />
            <input
              type="date"
              className="border border-gray-300 rounded-lg px-3 py-2 mr-2"
              name="date_fin"
              id="date_fin"
              value={dateFin}
              placeholder="Date de fin"
              onChange={handleDateChange}
            />
            <select
              className="border border-gray-300 rounded-lg px-3 py-2"
              name="user_filter"
              id="user_filter"
              onChange={(e) => setInputUserFilter(e.target.value)}
            >
              <option value="all">Tous les utilisateurs</option>
              {users.map((user) => (
                <option value={user.username} key={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </select>
          </div>
          <table className="w-full min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">N°</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">User</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Statut</th>
              </tr>
            </thead>
            <tbody id="transactions-body" className="bg-white divide-y divide-gray-200">
              {filteredTransactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.document_number}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.total}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.user_name || 'utilisateur inconnu'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.customer_name || 'client inconnu'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span
                      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${parseFloat(transaction.paid_amount.toString()) >= parseFloat(transaction.total.toString())
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                        }`}
                    >
                      {parseFloat(transaction.paid_amount.toString()) >= parseFloat(transaction.total.toString()) ? 'Payée' : 'En attente'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalesPage;