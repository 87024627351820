import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom'; // Add Outlet here
import LoginPage from './components/login-page.tsx';
import SubscriptionAdmin from './components/subscription-admin.tsx';
import Dashboard from './components/dashboard-buttons.tsx';
import SubscriptionPartner from './components/subscription-partner.tsx';
import Layout from './components/layout.jsx'; // Import the Layout component
import ProductsPage from './components/ProductsPage.jsx';
import StockPage from './components/StockPage.jsx';
import UsersPage from './components/UsersPage.jsx';
import SalePage from './components/SalePage.jsx';

// ProtectedRoute component
const ProtectedRoute = () => {
  const isLoggedIn = localStorage.getItem('token'); 
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

const SuperProtectedRoute = () => {
  const userRole = localStorage.getItem('userRole'); 
  return (userRole === 'admin') ? <Outlet /> : <Navigate to="/dashboard" />;
};

const PartnerProtectedRoute = () => {
  const userRole = localStorage.getItem('userRole'); 
  return (userRole === 'partner' || userRole === 'admin') ? <Outlet /> : <Navigate to="/dashboard" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
        />
        <Route path="/login" element={<LoginPage />} />

        {/* Wrap protected routes with the Layout component */}
        <Route element={<Layout />}>
          <Route path="/sales" element={<SalePage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/stock" element={<StockPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route element={<SuperProtectedRoute />}>
              <Route path="/subscription" element={<SubscriptionAdmin />} />
            </Route>
            <Route element={<PartnerProtectedRoute />}>
              <Route path="/partner" element={<SubscriptionPartner />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;