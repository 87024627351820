import React, { useState, useEffect } from 'react';
import { Calendar, User, Key, Clock, Edit, Trash } from 'lucide-react';

const SubscriptionAdmin = () => {
  interface Subscription {
    id: string;
    name: string;
    email: string;
    username: string;
    date_debut: string;
    date_expiration: string;
    role: string;
    statut: string;
    can_create?: string;
    logo?: string;
  }

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [formData, setFormData] = useState({
    id: '',
    companyName: '',
    email: '',
    username: '',
    password: '',
    startDate: '',
    endDate: '',
    isAdmin: false,
    isPartner: false,
    can_create: '',
    logo: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  // Fetch subscriptions on component mount
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await fetch('http://68.183.134.6:3001/api/subscriptions', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch subscriptions');
      }
      const data = await response.json();
      setSubscriptions(data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  // Fetch subscription details by ID
  const fetchSubscriptionDetails = async (id) => {
    try {
      const response = await fetch(`http://68.183.134.6:3001/api/subscriptions/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch subscription details');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching subscription details:', error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : type === "checkbox" ? checked : value,
      ...(name === "isAdmin" && { isPartner: false }),
      ...(name === "isPartner" && { isAdmin: false }),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = isEditing
        ? `http://68.183.134.6:3001/api/subscriptions/${formData.id}`
        : 'http://68.183.134.6:3001/api/subscriptions';
  
      const method = isEditing ? 'PUT' : 'POST';
  
      // If updating and no file is selected, send JSON
      if (isEditing && !formData.logo) {
        const response = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            companyName: formData.companyName,
            email: formData.email,
            username: formData.username,
            password: formData.password,
            startDate: formData.startDate,
            endDate: formData.endDate,
            isAdmin: formData.isAdmin,
            isPartner: formData.isPartner,
            can_create: formData.can_create,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update subscription');
        }
      } else {
        // If creating or updating with a file, use FormData
        const formDataToSend = new FormData();
        formDataToSend.append('companyName', formData.companyName);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('username', formData.username);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('startDate', formData.startDate);
        formDataToSend.append('endDate', formData.endDate);
        formDataToSend.append('isAdmin', formData.isAdmin);
        formDataToSend.append('isPartner', formData.isPartner);
        formDataToSend.append('can_create', formData.can_create);
  
        if (formData.logo) {
          formDataToSend.append('logo', formData.logo);
        }
  
        // Debug: Log FormData entries
        for (let [key, value] of formDataToSend.entries()) {
          console.log(key, value);
        }
  
        const response = await fetch(url, {
          method,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: formDataToSend,
        });
  
        if (!response.ok) {
          throw new Error(`Failed to ${isEditing ? 'update' : 'create'} subscription`);
        }
      }
  
      alert(`Subscription ${isEditing ? 'updated' : 'created'} successfully!`);
      resetForm();
      fetchSubscriptions(); // Refresh the list
    } catch (error) {
      console.error(`Error ${isEditing ? 'updating' : 'creating'} subscription:`, error);
      alert(`Failed to ${isEditing ? 'update' : 'create'} subscription.`);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://68.183.134.6:3001/api/subscriptions/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete subscription');
      }

      alert('Subscription deleted successfully!');
      fetchSubscriptions(); // Refresh the list
    } catch (error) {
      console.error('Error deleting subscription:', error);
      alert('Failed to delete subscription.');
    }
  };

  const handleUpdate = async (id) => {
    const subscription = await fetchSubscriptionDetails(id);
    if (subscription) {
      setFormData({
        id: subscription.id,
        companyName: subscription.name,
        email: subscription.email || '',
        username: subscription.username,
        password: '', // Ne pas pré-remplir le mot de passe pour des raisons de sécurité
        startDate: subscription.date_debut,
        endDate: subscription.date_expiration,
        isAdmin: subscription.role === 'admin',
        isPartner: subscription.role === 'partner',
        can_create: subscription.can_create || '',
        logo: subscription.logo || "",
      });
      setIsEditing(true); // Passer en mode édition
    }
  };

  const resetForm = () => {
    setFormData({
      id: '',
      companyName: '',
      email: '',
      username: '',
      password: '',
      startDate: '',
      endDate: '',
      isAdmin: false,
      isPartner: false,
      can_create: '',
      logo: "",
    });
    setIsEditing(false); // Réinitialiser le mode édition
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* En-tête */}
      <div className="max-w-4xl mx-auto mb-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-2">Gestion des Abonnements</h1>
        <p className="text-gray-600">Créez et gérez les accès utilisateurs</p>
      </div>

      {/* Formulaire de création/mise à jour */}
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6 mb-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">
          {isEditing ? 'Modifier Abonnement' : 'Nouvel Abonnement'}
        </h2>
        
        <form className="space-y-6" onSubmit={handleSubmit}>
          {/* Informations de l'entreprise */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Nom de l'entreprise
              </label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Nom de l'entreprise"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="email@entreprise.com"
                required
              />
            </div>
          </div>

          {/* Identifiants */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Identifiant
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  className="w-full pl-10 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Identifiant"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Mot de passe
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <Key className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full pl-10 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Mot de passe"
                  required
                />
              </div>
            </div>
          </div>

          {/* Dates */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Date de début
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="w-full pl-10 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Date d'expiration
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <Clock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  className="w-full pl-10 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* Options supplémentaires */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-800">Options</h3>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="admin"
                name="isAdmin"
                checked={formData.isAdmin}
                onChange={handleInputChange}
                className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="admin" className="ml-2 text-sm text-gray-700">
                Accès administrateur
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="partner"
                name="isPartner"
                checked={formData.isPartner}
                onChange={handleInputChange}
                className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="partner" className="ml-2 text-sm text-gray-700">
                Partenaire
              </label>
            </div>
            {formData.isPartner && (
              <>
                <div className="flex items-center">
                  <input
                    type="number"
                    name="can_create"
                    value={formData.can_create}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="Nombre de comptes à créer"
                  />
                </div>
                <div className="flex items-center">
                  <input
                    type="file"
                    accept="image/*"
                    name="logo"
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
          </div>

          {/* Boutons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={resetForm}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {isEditing ? 'Mettre à jour' : 'Créer l\'abonnement'}
            </button>
          </div>
        </form>
      </div>

      {/* Liste des abonnements */}
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow overflow-hidden">
        <h2 className="text-xl font-semibold text-gray-800 p-6 border-b">Abonnements actifs</h2>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Entreprise</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Identifiant</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Début</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Expiration</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Role</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {subscriptions.map((subscription) => (
                <tr key={subscription.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.username}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.date_debut}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.date_expiration}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.role}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={() => handleUpdate(subscription.id)}
                      className="px-2 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mr-2"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(subscription.id)}
                      className="px-2 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                      <Trash className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAdmin;