import React, { useState, useEffect } from 'react';
import { ShoppingCart, Package, Users, CreditCard, TrendingUp, BarChart2, DollarSign, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const Dashboard = () => {
  const [todaySales, setTodaySales] = useState(0);
  const [weekSales, setWeekSales] = useState(0);
  const [monthSales, setMonthSales] = useState(0);
  const [todayOrders, setTodayOrders] = useState(0);
  const [weekOrders, setWeekOrders] = useState(0);
  const [monthOrders, setMonthOrders] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [salesData, setSalesData] = useState([]);
  const [showTable, setShowTable] = useState('transactions');

  interface Product {
    id: number;
    name: string;
    price: number;
    purchase_price: number;
  }

  interface Stock {
    id: number;
    product: string;
    warehouse: string;
    qte: number;
  }

  interface User {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
  }

  const [products, setProducts] = useState<Product[]>([]);
  const [stock, setStock] = useState([]);
  const [users, setUsers] = useState([]);
  interface Transaction {
    id: number;
    document_number: string;
    date: string;
    total: number;
    user_name: string;
    customer_name: string;
    status: string;
    paid_amount: number;
  }

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [filteredTransactions, setFilteredTransactions] = useState(transactions);
  const [inputUserFilter, setInputUserFilter] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch stats from the backend
        const response = await fetch('http://68.183.134.6:3001/api/stats', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        // Update state with fetched data
        setTodaySales(Number(data.today.amount) || 0);
        setWeekSales(Number(data.week.amount) || 0);
        setMonthSales(Number(data.month.amount) || 0);
        setTodayOrders(Number(data.today.count) || 0);
        setWeekOrders(Number(data.week.count) || 0);
        setMonthOrders(Number(data.month.count) || 0);
        // Assuming data.salesData is an array of sales data
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    const getchart = async () => {
      try {
        // Fetch sales data from the backend
        const response = await fetch('http://68.183.134.6:3001/api/chart', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        const formattedSalesData = data.labels.map((label, index) => ({
          date: label,
          sales: data.values[index],
        }));

        setSalesData(formattedSalesData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    const transactions = async () => {
      try {
        // Fetch transactions from the backend
        const response = await fetch('http://68.183.134.6:3001/api/transactions', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
        }

        const data = await response.json();

        const top10Transactions = data.slice(0, 10);
        setTransactions(top10Transactions);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    const getProducts = async () => {
      try {
        // Fetch products from the backend
        const response = await fetch('http://68.183.134.6:3001/api/products', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    const getStock = async () => {
      try {
        // Fetch stock from the backend
        const response = await fetch('http://68.183.134.6:3001/api/stock', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setStock(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    const getUsers = async () => {
      try {
        // Fetch users from the backend
        const response = await fetch('http://68.183.134.6:3001/api/users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setUsers(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      }
    };

    transactions();
    getchart();
    getProducts();
    getStock();
    getUsers();
    fetchData();
  }, []);


  const handleDateChange = (event) => {
    const { name, value } = event.target;
    
    if (name === "date_debut") {
      setDateDebut(value);
    } else if (name === "date_fin") {
      setDateFin(value);
    }
  };
  useEffect(() => {
    const filterTransactions = () => {
      console.log('dateDebut', dateDebut);
      console.log('dateFin', dateFin);
      const filtered = transactions.filter((transaction) => {
        if (!dateDebut && !dateFin) {
          return true;
        }
        if (dateDebut && !dateFin) {
          return transaction.date >= dateDebut;
        }
        if (!dateDebut && dateFin) {
          return transaction.date <= dateFin;
        }
        return transaction.date >= dateDebut && transaction.date <= dateFin;
      });
      setFilteredTransactions(filtered);
      console.log('filtered', filtered);
    };
    filterTransactions();
  }
  , [dateDebut, dateFin]);
  

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <p className="text-gray-700">Chargement en cours...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <p className="text-red-500">Erreur: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* En-tête avec statistiques actuelles */}
      <div className="p-4">
        <header className="max-w-7xl mx-auto mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800 text-center mb-2">
            Tableau de bord Aronium
          </h1>
          <p className="text-center text-gray-600 mb-4">Vue d'ensemble de l'activité</p>
        </header>

        {/* Statistiques principales */}
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Ventes aujourd'hui</h3>
              <DollarSign className="h-6 w-6 text-blue-500" />
            </div>
            <p className="text-2xl font-bold text-gray-900 mb-2">
              <span id="today-sales">{todaySales.toFixed(2)}</span> MAD
            </p>
            <span className="inline-block px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
              {todayOrders} commandes
            </span>
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Cette semaine</h3>
              <Clock className="h-6 w-6 text-green-500" />
            </div>
            <p className="text-2xl font-bold text-gray-900 mb-2">
              <span id="week-sales">{weekSales.toFixed(2)}</span> MAD
            </p>
            <span className="inline-block px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
              {weekOrders} commandes
            </span>
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Ce mois</h3>
              <BarChart2 className="h-6 w-6 text-purple-500" />
            </div>
            <p className="text-2xl font-bold text-gray-900 mb-2">
              <span id="month-sales">{monthSales.toFixed(2)}</span> MAD
            </p>
            <span className="inline-block px-3 py-1 bg-purple-100 text-purple-800 rounded-full text-sm">
              {monthOrders} commandes
            </span>
          </div>
        </div>

        {/* Graphique des ventes par jour */}
        <div className="max-w-7xl mx-auto mt-6">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Évolution des ventes par jour</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={salesData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="sales" stroke="#8884d8" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <br />

        {/* Menu des boutons */}
        <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {/* Ventes */}
          <Link to="/sales" className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all hover:scale-105">            <div className="flex flex-col items-center">
              <ShoppingCart className="h-12 w-12 text-blue-500 mb-3" />
              <span className="text-lg font-semibold text-gray-700">Ventes</span>
            </div>
          </Link>

          {/* Produits */}
          {/* Produits */}
          <Link to="/products" className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all hover:scale-105">
            <div className="flex flex-col items-center">
              <Package className="h-12 w-12 text-green-500 mb-3" />
              <span className="text-lg font-semibold text-gray-700">Produits</span>
            </div>
          </Link>

          {/* État de stock */}
          <Link to="/stock" className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all hover:scale-105">
            <div className="flex flex-col items-center">
              <BarChart2 className="h-12 w-12 text-yellow-500 mb-3" />
              <span className="text-lg font-semibold text-gray-700">État de stock</span>
            </div>
          </Link>

          {/* Utilisateurs */}
          <Link to="/users" className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all hover:scale-105">
            <div className="flex flex-col items-center">
              <Users className="h-12 w-12 text-indigo-500 mb-3" />
              <span className="text-lg font-semibold text-gray-700">Utilisateurs</span>
            </div>
          </Link>

          {/* Caisse */}
          <button className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all hover:scale-105">
            <div className="flex flex-col items-center">
              <CreditCard className="h-12 w-12 text-pink-500 mb-3" />
              <span className="text-lg font-semibold text-gray-700">Caisse</span>
            </div>
          </button>

          {/* Profit Net */}
          <button className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all hover:scale-105">
            <div className="flex flex-col items-center">
              <TrendingUp className="h-12 w-12 text-teal-500 mb-3" />
              <span className="text-lg font-semibold text-gray-700">Profit Net</span>
            </div>
          </button>
        </div>

        {/* transactions */}
        {/* if showTable is transaction */}
        {showTable === 'transactions' && (
          <div className="max-w-7xl mx-auto mt-6">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Dernières transactions</h3>
              <div className="overflow-x-auto">
                {/* filter by time */}
                <div className="flex justify-start mb-4">
                  <input
                    type="date"
                    className="border border-gray-300 rounded-lg px-3 py-2 mr-2"
                    name="date_debut"
                    id="date_debut"
                    value={dateDebut}
                    placeholder="Date de début"
                    onChange={handleDateChange}
                  />
                  <input
                    type="date"
                    className="border border-gray-300 rounded-lg px-3 py-2 mr-2"
                    name="date_fin"
                    id="date_fin"
                    value={dateFin}
                    placeholder="Date de fin"
                    onChange={handleDateChange}
                  />
                  <select
                    className="border border-gray-300 rounded-lg px-3 py-2"
                    name="user_filter"
                    id="user_filter"
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputUserFilter(value);
                      if (value === 'all') {
                        setFilteredTransactions(transactions);
                      } else {
                        const filtered = transactions.filter((transaction) => transaction.user_name === value);
                        setFilteredTransactions(filtered);
                      }
                    }}
                  >
                    <option value="all">Tous les utilisateurs</option>
                    {/* get users */}
                    {users.map((user: User) => (
                      <option value={user.username} key={user.id}>{user.first_name} {user.last_name}</option>
                    ))}
                  </select>
                </div>
                <table className="w-full min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">N°</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">User</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Statut</th>
                    </tr>
                  </thead>
                  <tbody id="transactions-body" className="bg-white divide-y divide-gray-200">
                    {/* if filteredTransactions else transaction*/}
                    {(dateDebut || dateFin || inputUserFilter) ? filteredTransactions.map((transaction, index) => (
                      <tr key={transaction.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.document_number}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.total}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.user_name || 'utilisateur inconnu'}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.customer_name || 'client inconnu'}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${parseFloat(transaction.paid_amount.toString()) >= parseFloat(transaction.total.toString())
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                            }`}>
                            {parseFloat(transaction.paid_amount.toString()) >= parseFloat(transaction.total.toString()) ? 'Payée' : 'En attente'}
                          </span>
                        </td>
                      </tr>
                    )) : transactions.map((transaction, index) => (
                      <tr key={transaction.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.document_number}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.total}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.user_name || 'utilisateur inconnu'}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.customer_name || 'client inconnu'}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${parseFloat(transaction.paid_amount.toString()) >= parseFloat(transaction.total.toString())
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                            }`}>
                            {parseFloat(transaction.paid_amount.toString()) >= parseFloat(transaction.total.toString()) ? 'Payée' : 'En attente'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* products */}
        {/* if showTable is products */}
        {showTable === 'products' && (
          <div className="max-w-7xl mx-auto mt-6">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Liste des produits</h3>
              <div className="overflow-x-auto">
                <table className="w-full min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">N°</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nom</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Prix</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Prix d'achat</th>
                    </tr>
                  </thead>
                  <tbody id="products-body" className="bg-white divide-y divide-gray-200">
                    {products.map((product) => (
                      <tr key={product.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.id}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.price}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.purchase_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* stock */}
        {/* if showTable is stock */}
        {showTable === 'stock' && (
          <div className="max-w-7xl mx-auto mt-6">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">État de stock</h3>
              <div className="overflow-x-auto">
                <table className="w-full min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">N°</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Produit</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Entrepôt</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Quantité</th>
                    </tr>
                  </thead>
                  <tbody id="stock-body" className="bg-white divide-y divide-gray-200">
                    {stock.map((stock: Stock) => (
                      <tr key={stock.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.id}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.product}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.warehouse}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.qte}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* users */}
        {/* if showTable is users */}
        {showTable === 'users' && (
          <div className="max-w-7xl mx-auto mt-6">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Liste des utilisateurs</h3>
              <div className="overflow-x-auto">
                <table className="w-full min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">N°</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Prénom</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nom</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nom d'utilisateur</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Email</th>
                    </tr>
                  </thead>
                  <tbody id="users-body" className="bg-white divide-y divide-gray-200">
                    {users.map((user: User) => (
                      <tr key={user.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.id}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.first_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.last_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.username}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    
  );
};

export default Dashboard;